import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})

export class FirestoreService {
  currentUserPath: string;
  public db: any;
  constructor(
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {
    this.db = firestore;
  }

  getLive(path: string) {
    return this.firestore.collection(`${path}`).snapshotChanges();
  }

  get(path: string) {
    return this.firestore.collection(`${path}`).valueChanges();
  }

  update(path: string, docId, data) {
    return this.firestore.collection(`${path}`).doc(docId).update(data);
  }

  create(path, docId, data) {
    return this.firestore.collection(`${path}`).doc(docId).set(Object.assign({}, data));
  }

  set(path, data) {
    return this.firestore.collection(`${path}`).add(Object.assign({}, data));
  }

  rawGet(path): any {
    return this.firestore.collection(`${path}`);
  }

}
