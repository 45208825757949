import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreService } from '../firestore.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  uid: string;
  constructor(
    public db: FirestoreService,
    public fireauth: AngularFireAuth,
    private firestore: AngularFirestore
  ) {

  }

  getAllRestaurant() {
    this.uid = this.fireauth.auth.currentUser.uid;
    const email = this.fireauth.auth.currentUser.email;
    return this.firestore.collection('restaurant').ref.where('members', 'array-contains', email).get();
  }

  getRestaurant(restaurantId) {
    return this.firestore.collection('restaurant').doc(restaurantId).valueChanges();
  }

  getRestaurantDetails(restaurantId) {
    return this.firestore.collection('restaurant').doc(restaurantId).get().toPromise();
  }

  updateEvents(restaurantId, events) {
    return this.firestore.collection('restaurant').doc(restaurantId).set(events);
  }

  updateEventsStatus(restaurantId, event) {
    const ref = this.firestore.collection(`restaurant/${restaurantId}/history`).doc(`event_${moment.default().format('YYYYMMDD')}`);
    ref.get().subscribe((x: any) => {
      let history = x.data();
      if (!history || !history.events) {
        history = {
          events: []
        };
      }
      if (event.length > 0) {
        event.forEach(element => {
          element.nextdate = moment.default(element.nextdate).format('YYYY-MM-DD');
          element.response.userName = this.fireauth.auth.currentUser.displayName;
          history.events.push(element);
        });
      }
      ref.set(
        { events: history.events },
        { merge: true }
      );
    });
  }

  updateColumn(restaurantId, column) {
    this.firestore.collection(`restaurant`).doc(restaurantId).set(
      { column: column },
      { merge: true }
    );
  }
}
