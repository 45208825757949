import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: any;
  fcmtoken: string;

  constructor(
    public angularFireAuth: AngularFireAuth
  ) {
    this.fcmtoken = null;
    this.angularFireAuth.auth.setPersistence('local')
      .then(() => { });
    this.angularFireAuth.authState.subscribe(user => {
      if (user && user.emailVerified) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  public isAuthenticated(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null;
  }

  public setFcmToken(token: string) {
    this.fcmtoken = token;
  }

  public getFcmToken() {
    return this.fcmtoken;
  }

  public signin(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth.signInWithEmailAndPassword(email, password).then((resp) => {
        const user = this.angularFireAuth.auth.currentUser;
        resolve(user);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public forgotPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth.sendPasswordResetEmail(email).then((resp) => {
        resolve(resp);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public signup(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password).then((resp) => {
        const user = this.angularFireAuth.auth.currentUser;
        resolve(user);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public googleLogin() {
    return new Promise((resolve, reject) => {
      this.angularFireAuth.auth.setPersistence('local')
        .then(() => {
          this.angularFireAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then((resp) => {
            const user = this.angularFireAuth.auth.currentUser;
            resolve(user);
          }).catch((error) => {
            reject(error);
          });
        });
    });
  }

  public logout() {
    this.angularFireAuth.auth.signOut();
    localStorage.removeItem('user');
    localStorage.removeItem('isLoggedin');
  }

  isLoggedIn(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(map(a => a === null ? false : true));
  }

  public get userInfo() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      return user;
    }
    return false;
  }

}
