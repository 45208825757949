import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckTutorial } from './providers/check-tutorial.service';
import { AuthGaurdService } from './core/services/auth-gaurd.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/restaurant/list',
    pathMatch: 'full'
  },
  {
    path: 'account',
    canActivate: [AuthGaurdService],
    loadChildren: './pages/account/account.module#AccountModule'
  },
  {
    path: 'support',
    canActivate: [AuthGaurdService],
    loadChildren: './pages/support/support.module#SupportModule'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'signup',
    loadChildren: './pages/signup/signup.module#SignUpModule'
  },
  {
    path: 'app',
    canActivate: [AuthGaurdService],
    loadChildren: './pages/tabs-page/tabs-page.module#TabsModule'
  },
  {
    path: 'tutorial',
    loadChildren: './pages/tutorial/tutorial.module#TutorialModule',
    canLoad: [CheckTutorial]
  },
  {
    path: 'events',
    canActivate: [AuthGaurdService],
    children: [
      { path: 'add', loadChildren: './pages/events-add/events-add.module#EventsAddPageModule' },
      { path: 'main', loadChildren: './pages/events-main/events-main.module#EventsMainPageModule' },
      { path: 'details', loadChildren: './pages/events-details/events-details.module#EventsDetailsPageModule' },
      { path: 'master', loadChildren: './pages/events-master/events-master.module#EventsMasterPageModule' },
    ]
  },
  {
    path: 'restaurant',
    canActivate: [AuthGaurdService],
    children: [
      { path: 'pricing', loadChildren: './pages/pricing-plan/pricing-plan.module#PricingPlanPageModule' },
      { path: 'list', loadChildren: './pages/restaurant-list/restaurant-list.module#RestaurantListPageModule' },
      { path: 'add', loadChildren: './pages/restaurant-add/restaurant-add.module#RestaurantAddPageModule' },
      { path: 'action', loadChildren: './pages/restaurant-report/restaurant-report.module#RestaurantReportPageModule' },
      { path: 'history', loadChildren: './pages/event-explorer/event-explorer.module#EventExplorerPageModule' },
    ]
  },
  {
    path: 'restaurant-add-user',
    canActivate: [AuthGaurdService],
    loadChildren: './pages/restaurant-add-user/restaurant-add-user.module#RestaurantAddUserPageModule'
  },
  {
    path: 'event-explorer-details',
    canActivate: [AuthGaurdService],
    loadChildren: './pages/event-explorer-details/event-explorer-details.module#EventExplorerDetailsPageModule'
  },
  {
    path: 'privacy-policy',
    loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule'
  },
  {
    path: 'terms-conditions',
    loadChildren: './pages/terms-conditions/terms-conditions.module#TermsConditionsPageModule'
  },
  {
    path: 'auth-action',
    loadChildren: './pages/auth-action/auth-action.module#AuthActionPageModule'
  },
  {
    path: 'faq',
    loadChildren: './pages/faq/faq.module#FaqPageModule'
  },
  {
    path: 'pro-plan-details',
    loadChildren: './pages/pro-plan-details/pro-plan-details.module#ProPlanDetailsPageModule'
  },
  { path: 'form-center', loadChildren: './pages/form-center/form-center.module#FormCenterPageModule' },

  // { path: 'restaurant-manage', loadChildren: './pages/restaurant-manage/restaurant-manage.module#RestaurantManagePageModule' },
  // { path: 'events-export', loadChildren: './pages/events-export/events-export.module#EventsExportPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
