import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Events, MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { UserData } from './providers/user-data';
import { AuthService } from './core/services/auth.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';
import { mergeMapTo } from 'rxjs/operators';

const { PushNotifications, LocalNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [

  ];
  loggedIn = false;

  constructor(
    private events: Events,
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private authService: AuthService,
    private messaging: AngularFireMessaging
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    this.checkLoginStatus();
    this.listenForLoginEvents();
    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        showCloseButton: true,
        position: 'bottom',
        closeButtonText: `Reload`
      });
      await toast.present();
      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  cordovaInitializeApp() {
    PushNotifications.register();
    PushNotifications.addListener('registration',
      (token: PushNotificationToken) => {
        this.authService.setFcmToken(token.value);
        // alert('Push registration success, token: ' + token.value);
      }
    );
    PushNotifications.addListener('registrationError',
      (error: any) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotification) => {
        // alert('Push received: ' + JSON.stringify(notification));
        LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title,
              body: notification.body,
              id: 1,
              schedule: { at: new Date(Date.now() + 50) },
              sound: null,
              attachments: null,
              actionTypeId: '',
              extra: null
            }
          ]
        });
      }
    );
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  domInitializeApp() {
    this.getPermission();
  }

  initializeApp() {
    this.platform.ready().then((source) => {
      if (source === 'dom') {
        this.domInitializeApp();
      } else {
        this.cordovaInitializeApp();
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      }
    });
  }

  checkLoginStatus() {
    return this.authService.isLoggedIn().subscribe(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    this.events.subscribe('user:login', () => {
      this.updateLoggedInStatus(true);
    });
    this.events.subscribe('user:signup', () => {
      this.updateLoggedInStatus(true);
    });
    this.events.subscribe('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.authService.logout();
    this.userData.logout().then(() => {
      window.open('/login', '_self', 'location=yes');
      return false;
    });
  }

  openTutorial() {
    this.menu.enable(false);
    localStorage.setItem('ion_did_tutorial', 'false');
    this.router.navigateByUrl('/tutorial');
  }

  getPermission() {
    this.messaging.requestPermission
      .pipe(mergeMapTo(this.messaging.tokenChanges))
      .subscribe(token => {
        this.authService.setFcmToken(token);
        this.receiveMessage();
      });
  }

  receiveMessage() {
    this.messaging.messages
      .subscribe((message) => {
      });
  }
}
