// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  apiKey: 'AIzaSyD1IYMhfTDSqOTzo_uPLT3FHDjVVT67pkA',
  authDomain: 'not-68.firebaseapp.com',
  databaseURL: 'https://not-68.firebaseio.com',
  projectId: 'not-68',
  storageBucket: 'not-68.appspot.com',
  messagingSenderId: '849085449987',
  appId: '1:849085449987:web:f14e7bff69dd0977'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
