import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {
  constructor(private storage: Storage, private router: Router) { }

  canLoad() {
    const res = localStorage.getItem('ion_did_tutorial');
    if (true) {
      this.router.navigateByUrl('/restaurant/list');
      return false;
    } else {
      return true;
    }

  }
}
